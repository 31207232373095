<template>
  <div
    class="search_attribute"
    @click.stop
  >
    <div :class="&quot;key search_attribute_&quot; + selectedSearchKey.key">
      {{ selectedSearchKey.display_name }}:
    </div>
    <div class="search_value">
      <div class="expanding-field-container">
        <boolean-input
          v-if="selectedSearchKey.type == 'boolean'"
          :selected-search-key="selectedSearchKey"
        />
        <text-input
          v-if="selectedSearchKey.type == 'text'"
          :selected-search-key="selectedSearchKey"
        />
        <m-c-input
          v-if="selectedSearchKey.type == 'multiple_autocomplete'"
          :selected-search-key="selectedSearchKey"
        />
        <date-range-input
          v-if="selectedSearchKey.type == 'date_range'"
          :selected-search-key="selectedSearchKey"
        />
        <product-input
          v-if="selectedSearchKey.type == 'product'"
          :selected-search-key="selectedSearchKey"
        />
        <category-type-input
          v-if="selectedSearchKey.type == 'category_type'"
          :selected-search-key="selectedSearchKey"
        />
      </div>
    </div>
    <div
      class="remove"
      @click.stop="removeSearchKey(selectedSearchKey)"
    >
      <remove-svg class="remove-image" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import BooleanInput from './Boolean'
import TextInput from './Text'
import MCInput from './MultipleAutocomplete'
import DateRangeInput from './DateRange'
import ProductInput from './Product'
import CategoryTypeInput from './CategoryType'
import RemoveSvg from '@/assets/images/icons/x.svg'

export default {
  components: {
    BooleanInput,
    TextInput,
    MCInput,
    DateRangeInput,
    ProductInput,
    CategoryTypeInput,
    RemoveSvg
  },
  props: ['selectedSearchKey'],
  computed: {
    ...mapGetters([
      'searchKeys',
      'selectedSearchKeys'
    ])
  },
  methods: {
    ...mapMutations([
      'removeSelectedSearchKey'
    ]),
    removeSearchKey (key) {
      this.removeSelectedSearchKey(key)
      this.$cookies.set('savedSearchKeys', JSON.stringify(this.selectedSearchKeys), null, null, null, null, 'Lax')
    }
  }
}

</script>
<style lang="scss" scoped>
.search_attribute {
  align-items: center;
  background-color: $blue-5;
  border-radius: 3px;
  color: $blue-2;
  display: flex;
  flex: 0 0 auto;
  margin: 3px 5px;
  max-width: 99%;
  padding: 2px;

  .key {
    font-weight: $font-weight-bold;
    margin-left: 8px;
    text-transform: uppercase;
  }

  input {
    font-family: "Roboto Mono", monospace;
  }

  input[type='text'],
  select {
    width: 200px;
    display: inline;
  }

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    display: inline;
    margin-bottom: 0px;
    margin-top: 5px !important;
  }

  .key,
  .remove,
  .search_value {
    float: left;
  }

  .search_value {
    max-width: 87%;
  }
}

.remove {
  border: 1px solid $grey-2;
  border-radius: 3px;
  cursor: pointer;
  height: 18px;
  margin: 0 8px 0 2px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  .remove-image {
    height: 100%;
    width: 100%;
  }

  &:hover {
    background-color: $blue-3;
    border-color: $blue-3;
  }
}
</style>
<style lang="scss">
.input-width-container {
  min-width: 16px;
  width: 16px;
  input[type='checkbox'] {
    margin-top: 16px;
  }
}

.hidden-input {
  display: inline-block;
  height: 0 !important;
  overflow-y: hidden;
}

.vue-expand {
  border: 0 !important;
  padding: 0px !important;
  background: none !important;
  width: 100% !important;
}

.multiselect {
  min-height: 0;
}
.hlt-multiselect {
  .multiselect__tags {
    min-height: 0;
    height: 35px;
    padding: 4px;
    border: 0 !important;
  }

  .multiselect__select {
    border: 0px;
    box-shadow: none;
    padding: 0px;
  }
  .multiselect__placeholder {
    padding: 4px;
  }
  .searchbar input.multiselect__input {
    border: 0;
    box-shadow: none;
    background: none;
  }

  .multiselect__content-wrapper {
    margin-top: 16px;
  }
}
</style>
