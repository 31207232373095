<template>
  <div class="product_search_key">
    <div class="product-row">
      <div class="product-selection">
        <hlt-multiselect
          ref="multiselect"
          v-model="selectedApp"
          :options="currentUser.apps"
          :multiple="false"
          :show-labels="false"
          label="internal_name"
          class="product-select"
          track-by="id"
          placeholder="Select a product"
        />
      </div>
      <template v-if="!this.selectedSearchKey.hideCategories">
        <div class="category-selection">
          <hlt-multiselect
            ref="multiselect2"
            v-model="category"
            :options="categories"
            :multiple="false"
            :show-labels="false"
            label="name"
            track-by="id"
            class="category-select"
            placeholder="Select a category"
          />
        </div>
      </template>
      <div class="visibility-selection">
          <hlt-multiselect
            ref="multiselect3"
            v-model="visibility"
            :options="['On & Off', 'On', 'Off']"
            :multiple="false"
            :show-labels="false"
            class="category-select"
            placeholder="Select visibility"
          />
      </div>
      <div class="button-row">
        <button
          class="add_button button"
          @click="add"
        >
          Add
        </button>
      </div>
    </div>
    <div class="products">
      <div
        v-for="(product, i) in products"
        :key="i"
        class="product"
        :product="product"
      >
        <span
          v-if="product.app"
          class="app-name"
        >{{ product.app.internal_name }}</span>
        <span v-if="product.category">
          | {{ product.category.name }}
        </span>
        <span v-if="product.visibility">
          | <strong>Visibility: {{ product.visibility }}</strong>
        </span>
        <button @click="removeProduct(product)">
          x
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import railsRoutes from '../../../../store/rails-routes'
import HLTMultiselect from '../../../shared/VueMultiSelect'

export default {
  components: {
    'hlt-multiselect': HLTMultiselect
  },
  props: {
    selectedSearchKey: {
      type: Object
    }
  },
  data () {
    return {
      products: [],
      app: null,
      category: null,
      visibility: null,
      categoryName: '',
      categories: []
    }
  },
  computed: {
    selectedApp: {
      get () {
        return this.app
      },
      set (optionValue) {
        this.searchKeysHaveBeenModified()
        this.app = optionValue
        if (optionValue && (this.app != 'null')) {
          axios.get(railsRoutes.api_path({ type: 'categories' }), {
            params: { app_id: optionValue.id }
          }).then(response => {
            this.category = null
            this.categories = response.data.records.sort((a, b) => {
              return (a.name > b.name) - (a.name < b.name)
            })
          }).catch(e => {
            console.error(e)
            this.categories = []
          })
        } else {
          this.categories = []
        }
      }
    },
    ...mapGetters(['currentUser'])
  },
  mounted () {
    if (this.selectedSearchKey.value) {
      this.products = this.selectedSearchKey.value
    } else {
      if (this.$refs.multiselect) {
        if (this.$refs.multiselect.$refs.search) {
          this.$refs.multiselect.$refs.search.focus()
        }
      }
    }
  },
  methods: {
    removeProduct (product) {
      this.products.splice()
      var index = this.products.indexOf(product)
      this.products.splice(index, 1)
      this.updateSearchKeyValue({ key: this.selectedSearchKey.key, value: this.products })
    },
    add () {
      if (this.app) {
        if (!this.products.filter(x => x.app_id == this.app.id && x.category == this.category)[0]) {
          const categoryId = this.category ? this.category.id : 'null'
          this.products.push({
            app: this.app,
            app_id: this.app.id,
            category: this.category,
            category_id: categoryId,
            visibility: this.visibility
          })
          this.updateSearchKeyValue({ key: this.selectedSearchKey.key, value: this.products })
          this.category = null
        }
      }
    },
    ...mapMutations([
      'updateSearchKeyValue',
      'searchKeysHaveBeenModified'
    ])
  }
}
</script>
<style lang="scss">
.product_search_key {
  display: block;
  line-height: unset;
  .product-row {
    width: 100%;
    display: flex;
    .category-selection, .product-selection {
      width: 400px;
      margin: 0 10px;
    }
    .visibility-selection {
      width: 200px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .products {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 10px;
    .product {
      min-height: 22px;
      background-color: lighten($green-2, 20%);
      color: darken($grey-1, 20%);
      border-radius: 4px;
      font-size: .8em;
      padding-left: 15px;
      padding-top: 2px;
      margin-right: 10px;
      margin-bottom: 5px;
      margin-top: 10px;
      .app-name {
        color: darken($grey-1, 30%);
        font-weight: 555;
      }
      button {
        margin-left: 7px;
        font-size: 14px;
        margin-right: 0px;
        width: 22px;
        border-radius: 0.3125rem;
        color: darken($grey-1, 20%);
      }
      button:hover {
        background: darken($green-2, 8%)
      }
    }
  }
  .button-row {
    display: flex;
    justify-content: center;
    .add_button {
      height: 22px;
      padding: 0 10px;
      color: $blue-2;
      background: white;
      border: 2px solid $grey-3;
      margin-right: 30px;
    }
  }
}
</style>
