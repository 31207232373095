<template>
  <div
    v-if="selectedSearchKey"
    class="input-width-container"
    :style="{ width: inputWidth() + 'px'}"
  >
    <input
      ref="input"
      v-model="searchValue"
      tabindex="-1"
      class="expanding-field vue-expand"
    >
  </div>
</template>

<script>
export default {
  props: ['selectedSearchKey'],
  computed: {
    searchValue: {
      get () {
        return this.selectedSearchKey.value
      },
      set (value) {
        this.$store.commit('updateSearchKeyValue', { key: this.selectedSearchKey.key, value: value })
      }
    }
  },
  mounted () {
    this.$refs.input.focus()
  },
  methods: {
    inputWidth () {
      if (this.$refs.input) {
        return this.$refs.input.value.length * 10 + 16
      }
    }
  }
}
</script>
