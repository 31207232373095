import axios from 'axios'
import railsRoutes from '../store/rails-routes'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'cmsUsers',
      'currentUser',
      'apiAssetType'
    ]),
    sortedAdminUsers() {
      let users = this.cmsUsers.map((x) => {
        return { id: x.id, name: `${x.first_name} ${x.last_name} (${x.email})` }
      })
      return this._.sortBy(users, ['name'])
    }
  },
  methods: {
    ...mapMutations([
      'setCmsUsers',
      'resetSearchResults',
      'setSearchKeys',
      'selectSearchKey',
      'updateSearchKeyValue'
    ]),

    ...mapActions(['searching']),

    setKeys(keys) {
      this.setSearchKeys({ keys: keys })
      this.resetSearchResults()
      if (this.$router?.currentRoute.query.searchFilters) {
        axios
          .get(
            railsRoutes.api_path({
              type: 'cms_notifications',
              id: JSON.parse(this.$router?.currentRoute.query.searchFilters)
                .notificationId
            })
          )
          .then((response) => {
            this.selectSearchKey({ key: 'ids' })
            this.updateSearchKeyValue({
              key: 'ids',
              value: response.data.records[0].data.link.ids.join(';'),
              objValue: null
            })
            this.searching()
          })
      } else {
        this.searching()
      }
    },

    setAvailableKeys(keys) {
      if (
        this.currentUser.permissions[
          `can_search_by_user_on_${this.apiAssetType}s`
        ]
      ) {
        axios
          .get(railsRoutes.api_path({ type: 'admin_users' }))
          .then((response) => {
            this.setCmsUsers({ cmsUsers: response.data.records })
            keys.push({
              key: 'admin_user_ids',
              display_name: 'CMS User',
              type: 'multiple_autocomplete',
              options: this.sortedAdminUsers,
              trackBy: 'id',
              label: 'name'
            })
            this.setKeys(keys)
          })
      } else {
        this.setKeys(keys)
      }
    }
  }
}
