<template>
  <div class="category_search_key">
    <div class="category-row">
      <div class="app-selection">
        <hlt-multiselect
          ref="multiselect"
          v-model="selectedApp"
          :options="currentUser.apps"
          :multiple="false"
          :show-labels="false"
          label="internal_name"
          track-by="id"
          class="app-select"
          placeholder="Select an app"
        />
      </div>
      <div class="category-selection">
        <hlt-multiselect
          ref="multiselect2"
          v-model="selectedCategoryType"
          :options="categoryTypes"
          :multiple="false"
          :show-labels="false"
          label="name"
          track-by="id"
          class="category-select"
          placeholder="Select a category type"
        />
      </div>
      <div class="button-row">
        <button
          class="add_button button"
          @click="add"
        >
          Add
        </button>
      </div>
    </div>
    <div class="categories">
      <div
        v-for="(selected, i) in selectedCategoryTypes"
        :key="i"
        class="category"
      >
        <span
          v-if="selected.app"
          class="app-name"
        >{{ selected.app.internal_name }}</span>
        <span v-if="selected.categoryType">
          | {{ selected.categoryType.name }}
        </span>
        <button @click="removeProduct(selected)">
          x
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import railsRoutes from '../../../../store/rails-routes'
import HLTMultiselect from '../../../shared/VueMultiSelect'

// https://github.com/shentao/vue-multiselect

export default {
  components: {
    'hlt-multiselect': HLTMultiselect
  },
  props: ['selectedSearchKey'],
  data () {
    return {
      app: [],
      categoryTypes: [],
      selectedCategoryType: null,
      selectedCategoryTypes: []
    }
  },
  computed: {
    selectedApp: {
      get () {
        return this.app
      },
      set (optionValue) {
        this.searchKeysHaveBeenModified()
        this.app = optionValue
        if (optionValue && (this.app != 'null')) {
          axios.get(railsRoutes.api_path({ type: 'category_types' }), {
            params: { app_id: optionValue.id }
          }).then(response => {
            this.selectedCategoryType = null
            this.categoryTypes = response.data.records.sort((a, b) => {
              return (a.name > b.name) - (a.name < b.name)
            })
          })
        } else {
          this.categoryTypes = []
        }
      }
    },
    ...mapGetters(['currentUser'])
  },
  mounted () {
    if (this.selectedSearchKey.value) {
      this.selectedCategoryTypes = this.selectedSearchKey.value
    } else {
      if (this.$refs.multiselect) {
        if (this.$refs.multiselect.$refs.search) {
          this.$refs.multiselect.$refs.search.focus()
        }
      }
    }
  },
  methods: {
    removeProduct (product) {
      this.selectedCategoryTypes.splice()
      var index = this.selectedCategoryTypes.indexOf(product)
      this.selectedCategoryTypes.splice(index, 1)
      this.updateSearchKeyValue({ key: this.selectedSearchKey.key, value: this.selectedCategoryTypes.map(x => x.categoryTypeId) })
    },
    add () {
      if (this.app && this.selectedCategoryType) {
        if (!this.selectedCategoryTypes.filter(x => x.app_id == this.app.id && x.categoryType.id == this.selectedCategoryType.id)[0]) {
          const selectedCategoryTypeId = this.selectedCategoryType ? this.selectedCategoryType.id : 'null'
          this.selectedCategoryTypes.push({
            app: this.app,
            categoryType: this.selectedCategoryType,
            categoryTypeId: selectedCategoryTypeId
          })
          this.updateSearchKeyValue({ key: this.selectedSearchKey.key, value: this.selectedCategoryTypes })
          this.selectedCategoryType = null
        }
      }
    },
    ...mapMutations([
      'updateSearchKeyValue',
      'searchKeysHaveBeenModified'
    ])
  }
}
</script>
<style lang="scss">
.category_search_key {
  display: block;
  line-height: unset;
  .category-row {
    width: 100%;
    display: flex;
    .category-selection, .app-selection {
      width: 350px;
      margin: 0 10px;
    }
  }
  .categories {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 10px;
    .category {
      min-height: 22px;
      background-color: lighten($green-2, 20%);
      color: darken($grey-1, 20%);
      border-radius: 4px;
      font-size: .8em;
      padding-left: 15px;
      padding-top: 2px;
      margin-right: 10px;
      margin-bottom: 5px;
      margin-top: 10px;
      .app-name {
        color: darken($grey-1, 30%);
        font-weight: 555;
      }
      button {
        margin-left: 7px;
        font-size: 14px;
        margin-right: 0px;
        width: 22px;
        border-radius: 0.3125rem;
        color: darken($grey-1, 20%);
      }
      button:hover {
        background: darken($green-2, 8%)
      }
    }
  }
  .button-row {
    display: flex;
    justify-content: center;
    .add_button {
      height: 22px;
      padding: 0 10px;
      color: $blue-2;
      background: white;
      border: 2px solid $grey-3;
      margin-right: 30px;
    }
  }
}
</style>
