<template>
  <div
    v-if="selectedSearchKey"
    class="date-section"
  >
    <div
      v-if="showSelectedDates"
      class="selected-date"
      @click="triggerDate"
    >
      {{ selectedSearchKey.value }}
    </div>
    <FunctionalCalendar
      v-show="!showSelectedDates"
      ref="Calendar"
      v-model="searchValue"
      :configs="calendarConfigs"
      @selectedDaysCount="rangeSelected"
    />
  </div>
</template>
<script>

export default {
  props: ['selectedSearchKey'],
  data () {
    return {
      calendarData: {},
      calendarConfigs: {
        sundayStart: true,
        dateFormat: 'yyyy-mm-dd',
        isDatePicker: false,
        isDateRange: true,
        isMultiple: false,
        calendarsCount: 1,
        withTimePicker: false,
        changeMonthFunction: true
      },
      showSelectedDates: false
    }
  },
  computed: {
    searchValue: {
      get () {
        return this.calendarData
      },
      set (value) {
        this.calendarData = value
        const fullDate = `${value.dateRange.start} to ${value.dateRange.end}`
        this.$store.commit('updateSearchKeyValue', { key: this.selectedSearchKey.key, value: fullDate })
      }
    }
  },
  mounted () {
    // parse date from string and put back into an object
    if (this.selectedSearchKey.value) {
      const dateRange = this.selectedSearchKey.value.split(' to ')
      const startDate = dateRange[0]
      const endDate = dateRange[1]

      this.searchValue = {
        dateRange: {
          start: startDate,
          end: endDate
        }
      }
      this.showSelectedDates = true
    }
  },
  methods: {
    triggerDate () {
      this.showSelectedDates = false
    },
    rangeSelected () {
      this.showSelectedDates = true
    }
  }
}
</script>

<style lang="scss">
.selected-date {
  cursor: pointer;
  padding: 0 8px;
}
</style>
