<template>
  <multiselect
    ref="multiselect"
    v-model="searchValue"
    :options="selectedSearchKey.options"
    :multiple="true"
    :show-labels="false"
    :track-by="selectedSearchKey.trackBy"
    :label="selectedSearchKey.label"
    class="multiple-auto-complete"
  />
</template>

<script>
import Multiselect from '../../../shared/VueMultiSelect'
import { mapMutations } from 'vuex'
export default {
  components: { Multiselect },
  props: {
    selectedSearchKey: {
      type: Object,
      required: true
    }
  },
  computed: {
    searchValue: {
      get () {
        return this.selectedSearchKey.objValue
      },
      set (value) {
        if (!value) {
          return
        }
        this.updateSearchKeyValue({
          key: this.selectedSearchKey.key,
          value: Object.values(value).map(x => x[this.selectedSearchKey.trackBy]),
          objValue: value
        })
      }
    }
  },
  mounted () {
    if (this.selectedSearchKey.value == '') {
      if (this.$refs.multiselect) {
        if (this.$refs.multiselect.$refs.search) {
          this.$refs.multiselect.$refs.search.focus()
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateSearchKeyValue'
    ])
  }
}
</script>
<style lang="scss">
.multiple-auto-complete {
  margin: 0 5px;
  padding-right: 10px;
  .multiselect {
    background: $white-2;
    padding-bottom: 0;
    margin: 0 15px 0 10px;
    width: 100%;
    min-height: 35px;
    border: 0;
    border-radius: 5px;
    .multiselect__tag {
      background: rgba($blue-4, 0.4);
      color: rgba($blue-1, 0.9);
    }
    .multiselect__select {
      border: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 5px;
    }
    .multiselect__single {
      padding-left: 6px;
      margin-top: 7px;
      background: transparent !important;
    }
    .multiselect__input {
      background: transparent;
      border: 0;
      height: 40px;
      margin-top: 0;
    }
    .multiselect__tags-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .multiselect__content-wrapper {
      margin-top: .35rem;
      width: fit-content;
      min-width: 250px;
    }
    .multiselect__tags {
      padding: 0 6px;
      border-radius: 5px;
      height: 30px;
      line-height: 30px;
      margin-bottom: 0;

      .multiselect__input {
        background: transparent;
        border: 0;
        box-shadow: none;
      }
    }
    .multiselect__tag-icon:hover {
      background: none;
    }
    .multiselect__option--selected.multiselect__option--highlight {
      background: $blue-3;
      color: #fff;
    }
    .multiselect__option.multiselect__option--highlight {
      background: $blue-4;
      color: #fff;
    }
    .multiselect__placeholder {
      padding: 3px 6px 0;
      margin-bottom: 0px;
      font-size: .95rem;
    }
  }
}
</style>
