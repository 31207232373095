<template>
  <div class="spinner-loader">
    <div class="spring-spinner">
      <div class="spring-spinner-part top">
        <div class="spring-spinner-rotator" />
      </div>
      <div class="spring-spinner-part bottom">
        <div class="spring-spinner-rotator" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.spinner-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .spring-spinner, .spring-spinner * {
    box-sizing: border-box;
    margin: 0 auto;
  }

  .spring-spinner {
    height: 60px;
    width: 60px;
    align-self: center;
  }

  .spring-spinner .spring-spinner-part {
    overflow: hidden;
    height: calc(80px / 2);
    width: 80px;
  }

  .spring-spinner  .spring-spinner-part.bottom {
    transform: rotate(180deg) scale(-1, 1);
  }

  .spring-spinner .spring-spinner-rotator {
    width: 80px;
    height: 80px;
    border: calc(80px / 7) solid transparent;
    border-right-color: $blue-4;
    border-top-color: $blue-4;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spring-spinner-animation 3s ease-in-out infinite;
    transform: rotate(-200deg);
  }

  @keyframes spring-spinner-animation {
    0% {
      border-width: calc(80px / 7);
    }
    25% {
      border-width: calc(80px / 23.33);
    }
    50% {
      transform: rotate(115deg);
      border-width: calc(80px / 7);
    }
    75% {
      border-width: calc(80px / 23.33);
    }
    100% {
      border-width: calc(80px / 7);
    }
  }
}
</style>
