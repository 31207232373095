<template>
  <div class="input-width-container">
    <input
      ref="input"
      v-model="searchValue"
      tabindex="-1"
      class="expanding-field vue-expand"
      type="checkbox"
    >
  </div>
</template>

<script>
export default {
  props: ['selectedSearchKey'],
  computed: {
    searchValue: {
      get () {
        return this.selectedSearchKey.value
      },
      set (value) {
        this.$store.commit('updateSearchKeyValue', { key: this.selectedSearchKey.key, value: value })
      }
    }
  },
  mounted () {
    this.$refs.input.focus()
  },
  created () {
    if (!this.selectedSearchKey.value) {
      this.searchValue = false
    }
  },
  methods: {
  }
}
</script>
