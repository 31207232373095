<template>
  <div
    id="asset_search"
    class="searchbar columns"
    @keydown="keyDown"
  >
    <div
      id="search_box"
      @click="displaySearchKeys()"
    >
      <div
        v-if="searchTotal"
        class="search-total-box"
      >
        <p class="total-amount">
          {{ searchTotal | numFormat }}
        </p>
        <p>results</p>
      </div>
      <default-input
        v-for="(selectedSearchKey, index) in selectedVisibleSearchKeys"
        :key="'default-input-' + index"
        :selected-search-key="selectedSearchKey"
      />
    </div>
    <div>
      <button
        id="submit_search"
        class="hlt-button secondary search-button"
        form="asset_search"
        @click.stop="search()"
      >
        Search
      </button>
    </div>
    <template v-if="showKeyOptions">
      <ul
        v-if="searchKeys"
        id="search_keys"
        v-on-clickaway="hideSearchKeys"
        class="menu dropdown search-dropdown"
      >
        <li
          v-for="(key,i) in searchKeys"
          :id="&quot;search_key_&quot; + key.display_name"
          :key="'display-name-' + i"
          :class="cssClass(key.key)"
          tabindex="-1"
          @mouseover="hoverKey(key.key)"
          @click="displaySearchKey(key)"
        >
          {{ key.display_name }}
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DefaultInput from './search_keys/Default'
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  components: { DefaultInput },
  filters: {
    numFormat (num) {
      return Number(num).toLocaleString()
    }
  },
  mixins: [clickaway],
  data () {
    return {
      showKeyOptions: false,
      selectedKey: ''
    }
  },
  computed: {
    ...mapGetters([
      'searchKeys',
      'selectedSearchKeys',
      'searchTotal',
      'searchAssetType'
    ]),
    selectedVisibleSearchKeys () {
      return this.selectedSearchKeys.filter(k => (k.visible === undefined) || k.visible)
    }
  },
  created () {
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed () {
    window.removeEventListener('keydown', this.keyDown)
  },
  methods: {
    ...mapActions([
      'searching'
    ]),
    ...mapMutations([
      'selectSearchKey',
      'resetSearchResults',
      'setSearchUrl',
      'selectSearchKey'
    ]),
    hideSearchKeys () {
      this.showKeyOptions = false
    },
    displaySearchKeys () {
      this.showKeyOptions = true
      if (this.searchKeys.length > 0) {
        this.selectedKey = this.searchKeys[0].key
      }
    },
    displaySearchKey (key) {
      this.showKeyOptions = false
      if (!this.selectedSearchKeys.find(k => k.key === key.key)) {
        this.selectSearchKey(key)
      }
    },
    search () {
      this.$cookies.set('savedSearchKeys', JSON.stringify(this.selectedSearchKeys), null, null, null, null, 'Lax')
      this.$cookies.set('savedSearchAssetType', this.searchAssetType, null, null, null, null, 'Lax')

      this.resetSearchResults()
      this.searching()
    },
    cssClass (key) {
      if (this.selectedKey === key) {
        return 'search_key active'
      } else {
        return 'search_key'
      }
    },
    hoverKey (key) {
      this.selectedKey = key
    },
    keyDown (event) {
      if (this.showKeyOptions) {
        if (event.keyCode === 40) { // DownArrow
          this.downHighlight()
          event.preventDefault()
        } else if (event.keyCode === 38) { // UpArrow
          this.upHighlight()
          event.preventDefault()
        } else if (event.keyCode === 13) { // Enter
          var obj = this.searchKeys.find(o => o.key === this.selectedKey)
          this.displaySearchKey(obj)
          event.preventDefault()
        }
      } else {
        if (event.keyCode === 13) { // Enter
          this.search()
        } else if (event.keyCode === 9) { // Tab
          this.displaySearchKeys()
        }
      }
    },
    downHighlight () {
      var obj = this.searchKeys.find(o => o.key === this.selectedKey)
      var index = this.searchKeys.indexOf(obj)
      if ((index + 1) < this.searchKeys.length) {
        this.selectedKey = this.searchKeys[index + 1].key
      }
    },
    upHighlight () {
      var obj = this.searchKeys.find(o => o.key === this.selectedKey)
      var index = this.searchKeys.indexOf(obj)
      if (index > 0) {
        this.selectedKey = this.searchKeys[index - 1].key
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#asset_search {
  position: relative;
  display: flex;
  flex: 1;
}
.search-total-box {
  position: absolute;
  right: 0px;
  top: 0;
  background: $grey-4;
  margin: 0;
  padding: 5px;
  height: 100%;
  p {
    margin: 0;
    padding: 0 1.1rem;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: .85rem;
    font-weight: 600;
    color: $space-black;
  }
  .total-amount {
    margin: -5px;
  }
}
#search_box {
  position: relative;
  border: 1px solid $blue-4;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  min-height: 46px;
  padding: 3px 105px 3px 5px;
  width: 92%;
  z-index: 300;

 .expanding-label {
    font-weight: $font-weight-bold;
  }

  .expanding-field-container {
    display: inline-block;
    line-height: 16px;
    min-width: 10px;
    padding: 0 7px;
    white-space: nowrap;

    .expanding-field {
      line-height: 16px;
      min-height: 16px;
      min-width: 10px;
      outline: 0 !important;
      white-space: nowrap;
      &[contenteditable] {
        display: inline-block;
      }
    }
  }
}
.searchbar {
  height: auto;
  padding: 0;
  position: relative;

  input {
    border: 1px solid $grey-3;
    border-radius: 4px;
    height: 100%;
    padding: 8px 16px;
    &::placeholder, &::-webkit-input-placeholder {
      color: $grey-1;
      opacity: 1;
    }

    &::-moz-placeholder, &:-ms-input-placeholder {
      color: $grey-1;
      opacity: 1;
    }
  }
}
.search-dropdown {
  background: $white;
//  border: 2px solid $blue-4;
//  border-radius: 3px;
  cursor: pointer;
  display: block;
  left: auto !important;
  margin-top: 0px;
  position: absolute !important;
  left: 0 !important;
  width: 20%;
  z-index: 9999;

  border: 1px solid $blue-4;
  border-radius: 6px;
  box-shadow: 0px 0px 6px 6px $drop-shadow-blue;

  li {
    border-top: 1px solid $grey-3;
    border-bottom: 1px solid $grey-3;
    color: $blue-2;
    font-size: 14px;
    padding: 4px;
    padding-left: 15px;

    &:first-child {
      border-top: 0;
      border-radius: 6px 6px 0 0;
    }

    &:last-child {
      border-bottom: 0;
      border-radius: 0 0 6px 6px;
    }

    &:hover, &.active {
      background-color: $blue-2;
      color: $white;
      font-weight: $font-weight-bold;
    }
  }
}
.search-button {
  height: 45px;
  margin-left: 20px;
  padding: 12px 14px;
}
</style>
